// src/App.js
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './assets/scss/main.scss';
import MenuBar from './components/MenuBar';
import ServerPage from './pages/ServerPage';

const App = () => {
	const [loading, setLoading] = useState(false);
	const location = useLocation();

	useEffect(() => {
		setLoading(true);
		const timer = setTimeout(() => setLoading(false), 300); // Add slight delay for smoother experience
		return () => clearTimeout(timer);
	}, [location]);

	return (
		<div style={{ display: 'flex', height: '100vh' }}>
			<MenuBar />
			<div style={{ flex: 1, padding: '20px' }}>
				{loading ? (
					<div>Loading...</div>
				) : (
					<Routes>
						<Route path='/:server' element={<ServerPage />} />
					</Routes>
				)}
			</div>
		</div>
	);
};

export default App;
