import axios from 'axios';
import React, { useState } from 'react';

const TerritoryCheck = ({ server }) => {
	const [posX, setPosX] = useState('');
	const [posZ, setPosZ] = useState('');
	const [results, setResults] = useState(null);
	const [error, setError] = useState('');
	const [copied, setCopied] = useState(''); // To manage copied state

	const backendUrl = process.env.REACT_APP_BACKEND_URL;

	const handleSearch = async (e) => {
		e.preventDefault();
		setError('');
		setResults(null);

		try {
			const response = await axios.get(
				`${backendUrl}/api/${server}/territory-search/${posX}/${posZ}`
			);
			setResults(response.data);
		} catch (err) {
			setError('Territory not found');
		}
	};

	// Handle copy coordinates functionality
	const handleCopyCoordinates = (posX, posY, posZ) => {
		const coordinates = `${Math.floor(posX)},${Math.floor(
			posY
		)},${Math.floor(posZ)}`;
		navigator.clipboard.writeText(coordinates).then(() => {
			setCopied(coordinates);
			setTimeout(() => setCopied(''), 2000); // Reset copied state after 2 seconds
		});
	};

	// Function to determine if all members belong to the same group
	const getUniqueGroups = (groups) => {
		const uniqueGroups = [
			...new Set(groups.map((group) => group.shortname)),
		];
		return uniqueGroups;
	};

	const handleCopy = (text) => {
		navigator.clipboard.writeText(text).then(() => {
			setCopied(text);
			setTimeout(() => setCopied(''), 2000); // Reset copied state after 2 seconds
		});
	};

	return (
		<div className='max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg'>
			<form onSubmit={handleSearch} className='mb-6 space-y-4'>
				<div className='flex items-center space-x-4'>
					<input
						type='number'
						placeholder='Enter X axis'
						value={posX}
						onChange={(e) => setPosX(e.target.value)}
						className='w-full px-4 py-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-300'
					/>
					<input
						type='number'
						placeholder='Enter Z axis'
						value={posZ}
						onChange={(e) => setPosZ(e.target.value)}
						className='w-full px-4 py-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-300'
					/>
				</div>
				<button
					type='submit'
					className='w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-300'
				>
					Search
				</button>
			</form>

			{error && <p className='text-red-500'>{error}</p>}

			{results && (
				<div className='territory-results space-y-6'>
					<div className='territory p-4 bg-gray-100 rounded-lg shadow-sm'>
						<h3 className='text-lg font-semibold mb-2'>
							Territory
						</h3>
						<p className='flex justify-between items-center'>
							<span>GUID</span>
							<span className='text-gray-700 font-mono'>
								{results.territory.GUID}
							</span>
						</p>
						<p
							className='coords flex justify-between items-center cursor-pointer hover:text-blue-600'
							onClick={() =>
								handleCopyCoordinates(
									results.territory.Pos_x,
									results.territory.Pos_y,
									results.territory.Pos_z
								)
							}
							title='Click to copy coordinates'
						>
							<span>Coordinates</span>
							<span className='text-gray-700 font-mono'>
								{Math.floor(results.territory.Pos_x)} x{' '}
								{Math.floor(results.territory.Pos_z)} (Y:{' '}
								{Math.floor(results.territory.Pos_y)})
							</span>
						</p>
						<p className='flex justify-between items-center'>
							<span>Admin</span>
							<span className='text-gray-700'>
								{results.territory.Members[0].Name}
							</span>
						</p>
					</div>

					<div className='members p-4 bg-gray-100 rounded-lg shadow-sm'>
						<h3 className='text-lg font-semibold mb-2'>Members</h3>
						<div className='space-y-2'>
							{results.territory.Members.length > 0 ? (
								results.territory.Members.map(
									(member, index) => (
										<p
											key={index}
											className='cursor-pointer hover:text-blue-600 flex justify-between items-center'
											onClick={() =>
												handleCopy(member.UID)
											}
											title='Click to copy Steam ID'
										>
											<span>{member.Name}</span>
											<span className='text-gray-700 font-mono'>
												({member.UID})
											</span>
										</p>
									)
								)
							) : (
								<p>No members found</p>
							)}
						</div>
					</div>

					{results && (
						<div className='groups p-4 bg-gray-100 rounded-lg shadow-sm'>
							<h3 className='text-lg font-semibold mb-2'>
								Groups
							</h3>

							{results.hasMembersWithoutGroup ||
							results.uniqueGroups.length > 1 ? (
								<div>
									{/* Loop through all members and show if they belong to a group or not */}
									{results.territory.Members.map((member) => {
										const memberGroups = results.groups
											.filter(
												(group) =>
													group.member === member.Name
											)
											.map((group) => group.shortname);

										return (
											<p key={member.UID}>
												{memberGroups.length === 0 ? (
													// Member with no group
													<span>
														{member.Name} does not
														belong to any group
													</span>
												) : memberGroups.length ===
												  1 ? (
													// Member in a single group
													<span>
														{member.Name} is in
														group {memberGroups[0]}
													</span>
												) : (
													// Member in multiple groups (unlikely, but handle the case)
													<span>
														{member.Name} is in
														multiple groups:{' '}
														{memberGroups.join(
															', '
														)}
													</span>
												)}
											</p>
										);
									})}
								</div>
							) : (
								// All members in the same group case
								<p>
									All members are in group:{' '}
									{results.uniqueGroups[0]}
								</p>
							)}
						</div>
					)}

					{copied && (
						<p className='text-green-500'>Copied: {copied}</p>
					)}
				</div>
			)}
		</div>
	);
};

export default TerritoryCheck;
