import axios from 'axios';
import React, { useState } from 'react';

const AltAccountCheck = () => {
	const [steamID, setSteamID] = useState('');
	const [altAccounts, setAltAccounts] = useState(null);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [copied, setCopied] = useState('');

	const handleSearch = async (e) => {
		e.preventDefault();
		setError('');
		setAltAccounts(null);
		setLoading(true);

		try {
			const response = await axios.get(
				'https://aftermath-gaming.com/api/v1/player/getMultiAccountUsers'
			);
			const accounts = response.data;

			const foundAccount = accounts.find((account) =>
				account.steam_ids.split(', ').includes(steamID)
			);

			if (foundAccount) {
				const associatedSteamIDs = foundAccount.steam_ids
					.split(', ')
					.filter((id) => id !== steamID);

				setAltAccounts(associatedSteamIDs);
			} else {
				setError('No alt accounts found for this Steam ID');
			}
		} catch (err) {
			setError('Error fetching data from API');
		} finally {
			setLoading(false);
		}
	};

	const handleCopy = (id) => {
		navigator.clipboard.writeText(id).then(() => {
			setCopied(id);
			setTimeout(() => setCopied(''), 2000);
		});
	};

	return (
		<div className='max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg space-y-6'>
			<h3 className='text-lg font-semibold'>Check for Alt Accounts</h3>
			<form onSubmit={handleSearch} className='space-y-4'>
				<input
					type='text'
					placeholder='Enter Steam ID'
					value={steamID}
					onChange={(e) => setSteamID(e.target.value)}
					className='w-full px-4 py-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-300'
				/>
				<button
					type='submit'
					className='w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-300'
				>
					Search
				</button>
			</form>

			{error && <p className='text-red-500'>{error}</p>}

			{loading ? (
				<div className='text-center text-blue-500 font-semibold'>
					Loading...
				</div>
			) : (
				altAccounts && (
					<div className='alt-accounts space-y-4'>
						<h3 className='text-md font-semibold'>
							Associated Alt Accounts:
						</h3>
						<div className='space-y-2'>
							{altAccounts.map((id, index) => (
								<p
									key={index}
									className='p-2 bg-gray-100 rounded-md cursor-pointer hover:bg-blue-100'
									onClick={() => handleCopy(id)}
									title='Click to copy'
								>
									{id}{' '}
									{copied === id && (
										<span className='text-green-500'>
											(Copied!)
										</span>
									)}
								</p>
							))}
						</div>
					</div>
				)
			)}
		</div>
	);
};

export default AltAccountCheck;
