import { Box, Button } from '@mui/material';
import React, { useState } from 'react';

const ButtonMenu = ({ setSelectedComponent }) => {
	const [activeButton, setActiveButton] = useState('UserSearch'); // Track the active button

	const handleButtonClick = (componentName) => {
		setSelectedComponent(componentName); // Update selected component
		setActiveButton(componentName); // Update active button state
	};

	const menuItems = [
		{ name: 'User search', value: 'UserSearch' },
		{ name: 'Group check', value: 'GroupCheck' },
		{ name: 'Territory check', value: 'TerritoryCheck' },
		{ name: 'Search alt account', value: 'AltAccountCheck' },
		{ name: 'Dupe check', value: 'DupeCheck' },
		{ name: 'Removed items', value: 'RemovedItems' },
	];

	return (
		<Box className='button-menu-container flex flex-col space-y-2'>
			{menuItems.map((item) => (
				<Button
					key={item.value}
					variant={
						activeButton === item.value ? 'contained' : 'outlined'
					}
					onClick={() => handleButtonClick(item.value)}
					sx={{
						color: activeButton === item.value ? 'white' : 'gray',
						backgroundColor:
							activeButton === item.value
								? 'rgb(59 130 246)'
								: 'transparent',
						borderColor: 'rgb(209 213 219)',
						borderRadius: '8px',
						'&:hover': {
							backgroundColor:
								activeButton === item.value
									? 'rgb(37 99 235)'
									: 'rgb(229 231 235)',
						},
						transition: 'background-color 0.3s ease',
					}}
				>
					{item.name}
				</Button>
			))}
		</Box>
	);
};

export default ButtonMenu;
