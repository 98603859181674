import { List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import '../assets/scss/menubar.scss'; // SCSS file import

const MenuBar = () => {
	const menuItems = [
		{ name: 'Cherno 1', to: '/chernarus1' },
		{ name: 'Cherno 2', to: '/chernarus2' },
		{ name: 'Cherno 3', to: '/chernarus3' },
		{ name: 'Deer Isle', to: '/deerisle' },
		{ name: 'Livonia 1', to: '/livonia1' },
		{ name: 'Livonia 2', to: '/livonia2' },
		{ name: 'Namalsk', to: '/namalsk' },
	];

	return (
		<div className='menu-bar flex flex-col h-screen bg-gray-900 p-4'>
			<Typography variant='h5' className='text-white font-bold mb-4'>
				Game Servers
			</Typography>
			<List>
				{menuItems.map((item, index) => (
					<ListItem key={index} disablePadding>
						<NavLink
							to={item.to}
							className={({ isActive }) =>
								`w-full py-2 px-4 rounded-lg text-white no-underline transition-colors ${
									isActive
										? 'bg-green-500'
										: 'hover:bg-gray-700'
								}`
							}
						>
							<ListItemText primary={item.name} />
						</NavLink>
					</ListItem>
				))}
			</List>
		</div>
	);
};

export default MenuBar;
