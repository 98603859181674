import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // For accessibility reasons with react-modal

const GroupCheck = ({ server, preloadedGroup }) => {
	const [groupName, setGroupName] = useState(preloadedGroup || '');
	const [groupData, setGroupData] = useState(null);
	const [territories, setTerritories] = useState([]);
	const [error, setError] = useState('');
	const [selectedTerritory, setSelectedTerritory] = useState(null);
	const [cftoolsProfileLinks, setCftoolsProfileLinks] = useState({}); // Store CFTools profile links

	const backendUrl = process.env.REACT_APP_BACKEND_URL;

	useEffect(() => {
		if (preloadedGroup) {
			handleSearch();
		}
	}, [preloadedGroup]);

	const handleSearch = async (e) => {
		if (e) e.preventDefault();
		setError('');
		setGroupData(null);
		setTerritories([]);
		try {
			const response = await axios.get(
				`${backendUrl}/api/groups/${server}/group-check/${groupName}`
			);
			setGroupData(response.data.group);
			setTerritories(response.data.territories);
			response.data.group.members.forEach((member) => {
				fetchCFToolsProfile(member.steamid);
			});
		} catch (err) {
			setError('Group not found');
		}
	};

	const fetchCFToolsProfile = async (steamID) => {
		try {
			const response = await axios.get(
				`${backendUrl}/api/cftools/profile/${steamID}`
			);
			if (response.data && response.data.cftools_id) {
				setCftoolsProfileLinks((prevLinks) => ({
					...prevLinks,
					[steamID]: `https://app.cftools.cloud/profile/${response.data.cftools_id}`,
				}));
			}
		} catch (error) {
			console.error(
				`Error fetching CFTools profile for ${steamID}:`,
				error
			);
		}
	};

	const handleTerritoryClick = (territory) => {
		setSelectedTerritory(territory);
	};

	const closeModal = () => {
		setSelectedTerritory(null);
	};

	const formatCoordinates = (coord) => Math.floor(coord);

	const getNonGroupMembers = (territoryMembers, groupMembers) => {
		const groupMemberUIDs = groupMembers.map((member) => member.steamid);
		return territoryMembers.filter(
			(member) => !groupMemberUIDs.includes(member.UID)
		);
	};

	return (
		<div className='p-6'>
			<h3 className='text-lg font-semibold mb-4'>
				Search a group by entering the "Shortname" or full name
			</h3>
			<form className='flex gap-4 mb-6' onSubmit={handleSearch}>
				<input
					type='text'
					placeholder='Enter group name'
					value={groupName}
					onChange={(e) => setGroupName(e.target.value)}
					className='border p-2 rounded-md w-64'
				/>
				<button
					type='submit'
					className='bg-blue-500 text-white px-4 py-2 rounded-md'
				>
					Search
				</button>
			</form>

			{error && <p className='text-red-500'>{error}</p>}

			{groupData && (
				<div className='space-y-8'>
					<div className='mb-8'>
						<h2 className='text-xl font-bold'>
							Group Name: {groupData.name}
						</h2>
					</div>

					<div>
						<h3 className='text-lg font-semibold'>Members</h3>
						<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4'>
							{groupData.members.map((member, index) => (
								<a
									className='border p-4 rounded-lg shadow-md hover:shadow-lg transition-all'
									key={index}
									href={
										cftoolsProfileLinks[member.steamid] ||
										'#'
									}
									target='_blank'
									rel='noopener noreferrer'
								>
									<h4 className='text-md font-semibold'>
										{member.name}
									</h4>
									<p className='text-sm text-gray-600'>
										{cftoolsProfileLinks[member.steamid]
											? 'Click to view CFTools Profile'
											: 'Fetching CFTools Profile...'}
									</p>
								</a>
							))}
						</div>
					</div>

					<div>
						<h3 className='text-lg font-semibold'>Territories</h3>
						<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4'>
							{territories.length > 0 ? (
								territories.map((territory, index) => (
									<div
										className='border p-4 rounded-lg shadow-md hover:shadow-lg transition-all cursor-pointer'
										key={index}
										onClick={() =>
											handleTerritoryClick(territory)
										}
									>
										<h4 className='font-semibold'>
											Territory {index + 1}
										</h4>
									</div>
								))
							) : (
								<p>No territories found for this group.</p>
							)}
						</div>
					</div>

					<div>
						<h3 className='text-lg font-semibold'>
							Players in Territory Not in Group
						</h3>
						<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4'>
							{territories.length > 0 &&
								territories.map((territory, index) => {
									const nonGroupMembers = getNonGroupMembers(
										territory.Members,
										groupData.members
									);
									return nonGroupMembers.length > 0 ? (
										<div key={index}>
											<h4 className='font-semibold'>
												Territory {index + 1}
											</h4>
											<div className='grid grid-cols-1 gap-4 mt-2'>
												{nonGroupMembers.map(
													(member) => (
														<a
															className='border p-4 rounded-lg shadow-md hover:shadow-lg transition-all'
															key={member.UID}
															href={
																cftoolsProfileLinks[
																	member.UID
																] || '#'
															}
															target='_blank'
															rel='noopener noreferrer'
														>
															<h4 className='text-md font-semibold'>
																{member.Name}
															</h4>
															<p className='text-sm text-gray-600'>
																{cftoolsProfileLinks[
																	member.UID
																]
																	? 'View CFTools Profile'
																	: 'Fetching CFTools Profile...'}
															</p>
														</a>
													)
												)}
											</div>
										</div>
									) : (
										<p>
											No non-group members found in
											Territory {index + 1}.
										</p>
									);
								})}
						</div>
					</div>
				</div>
			)}

			{selectedTerritory && (
				<Modal
					isOpen={!!selectedTerritory}
					onRequestClose={closeModal}
					contentLabel='Territory Details'
					className='modal-content'
					overlayClassName='modal-overlay'
				>
					<h2 className='text-xl font-bold mb-4'>
						Territory Details
					</h2>
					<p
						className='coords cursor-pointer'
						onClick={() =>
							navigator.clipboard.writeText(
								`${Math.floor(
									selectedTerritory.Pos_x
								)},${Math.floor(
									selectedTerritory.Pos_y
								)},${Math.floor(selectedTerritory.Pos_z)}`
							)
						}
						title='Click to copy coordinates'
					>
						<strong>Coordinates:</strong>{' '}
						{formatCoordinates(selectedTerritory.Pos_x)} x{' '}
						{formatCoordinates(selectedTerritory.Pos_z)}
					</p>
					<p>
						<strong>Territory UID:</strong> {selectedTerritory.GUID}
					</p>

					<h3 className='text-lg font-semibold mt-6'>Admins</h3>
					{selectedTerritory.AdminUIDS &&
					selectedTerritory.AdminUIDS.length > 0 ? (
						<ul className='list-disc ml-5'>
							{selectedTerritory.AdminUIDS.map(
								(adminUID, index) => {
									const admin =
										selectedTerritory.Members.find(
											(member) => member.UID === adminUID
										);
									return (
										<li key={index}>
											{admin
												? `${admin.Name} (${admin.UID})`
												: `Admin (${adminUID})`}
										</li>
									);
								}
							)}
						</ul>
					) : (
						<p>No Admins Found</p>
					)}

					<h3 className='text-lg font-semibold mt-4'>Members</h3>
					<ul className='list-disc ml-5'>
						{selectedTerritory.Members.map((member, index) => (
							<li key={index}>
								{member.Name} ({member.UID})
							</li>
						))}
					</ul>

					<button
						onClick={closeModal}
						className='mt-6 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600'
					>
						Close
					</button>
				</Modal>
			)}
		</div>
	);
};

export default GroupCheck;
