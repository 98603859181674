import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import AltAccountCheck from '../components/AltAccountCheck';
import ButtonMenu from '../components/ButtonMenu';
import GroupCheck from '../components/GroupCheck';
import TerritoryCheck from '../components/TerritoryCheck';
import UserSearch from '../components/UserSearch';

const ServerPage = () => {
	const { server } = useParams();

	const serverMap = {
		chernarus1: 'cherno1',
		chernarus2: 'cherno2',
		chernarus3: 'cherno3',
		deerisle: 'deerisle',
		livonia1: 'livonia1',
		livonia2: 'livonia2',
		namalsk: 'namalsk',
	};

	const backendServerName = serverMap[server];
	const [selectedComponent, setSelectedComponent] = useState('UserSearch'); // Default to GroupCheck
	const [preloadedGroup, setPreloadedGroup] = useState(''); // Group name to preload in GroupCheck

	if (!backendServerName) {
		return (
			<Box
				display='flex'
				justifyContent='center'
				alignItems='center'
				height='100vh'
			>
				<Typography variant='h4' color='error'>
					Server not found
				</Typography>
			</Box>
		);
	}

	const renderComponent = () => {
		switch (selectedComponent) {
			case 'UserSearch':
				return (
					<UserSearch
						server={backendServerName}
						onGroupClick={handleGroupClick}
					/>
				);
			case 'GroupCheck':
				return (
					<GroupCheck
						server={backendServerName}
						preloadedGroup={preloadedGroup}
					/>
				);
			case 'TerritoryCheck':
				return <TerritoryCheck server={backendServerName} />;
			case 'AltAccountCheck':
				return <AltAccountCheck />;
			default:
				return null;
		}
	};

	// Handle when a group name is clicked in UserSearch
	const handleGroupClick = (groupName) => {
		setPreloadedGroup(groupName); // Set the group name to be preloaded in GroupCheck
		setSelectedComponent('GroupCheck'); // Switch to GroupCheck
	};

	return (
		<Box
			className='serverPage'
			display='flex'
			height='100vh'
			p={2}
			bgcolor='gray-100'
		>
			<Grid container spacing={2}>
				<Grid item xs={8}>
					<Paper elevation={3} className='p-6'>
						<Typography
							variant='h4'
							className='mb-4'
							fontWeight='bold'
						>
							{server.toUpperCase()}
						</Typography>
						{renderComponent()}
					</Paper>
				</Grid>
				<Grid item xs={4}>
					<Paper elevation={3} className='p-4'>
						<ButtonMenu
							setSelectedComponent={setSelectedComponent}
						/>
					</Paper>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ServerPage;
